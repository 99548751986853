import React, { useEffect, useState } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import styled, { css } from "styled-components";
import { white } from "../theme/colors";
import moment from "moment";
import { Button } from "../components/Button";
import { font } from "../theme/typography";
import momentRange from '../helpers/utils/moment-range'

const DatePickerStyle = styled.div`
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
  .DateRangePicker {
    background: ${white};
    z-index: 3;
    .DateRangePicker__Month {
      margin: 0 4px;
      width: ${(props) => props.width || '275px'};
      ${(props) =>
        props.calenderWidth &&
        css`
          width: ${props.calenderWidth};
        `}
    }
  }
`;

const DatePickerContainer = styled.div`
  top: ${(props) => (props.realtivePosition ? "0" : "100%")};
  position: ${(props) => (props.realtivePosition ? "relative" : "absolute")};
  background: ${white};
  z-index: 3;
  box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
  margin: 10px 0;
  ${(props) =>
    props.alignment === "left" &&
    css`
      left: 0;
    `}
  ${(props) =>
    props.alignment === "right" &&
    css`
      right: 0;
    `}
    ${(props) =>
    props.alignment === "center" &&
    css`
      right: -136px;
    `}
    button {
    min-width: 56px;
    margin-bottom: 10px;
    font-size: ${font.mini};
  }
`;
const Footer = styled.div`
  display: ${({ showFooter }) => (showFooter ? "flex" : "none")};
  justify-content: flex-end;
`;

const DatePicker = ({
  showCalendar = false,
  singleDateSelection = false,
  getSelectedDates = null,
  name,
  value,
  alignment = "left",
  children,
  showFooter = true,
  ...rest
}) => {
  const [isDatepickerOpen, setDatePickerOpen] = useState(showCalendar || false);
  const [selectedDate, setSelectedDate] = useState(value || null);
  // const wrapperRef = useOutsideClick(() => setDatePickerOpen(false));
  const footerVisible = showFooter && !showCalendar;

  useEffect(() => {
    if(!value) {
      setSelectedDate(null)
    } else {
      setSelectedDate(momentRange.range(value.start, value.end))
    }
  },[value])

  const onSelect = (dates) => {
    setSelectedDate(dates);
    getSelectedDates && getSelectedDates({ name, dates });
  };

  const onlySingleDate = (date) => {
    getSelectedDates({ name, dates: date.format("YYYY-MM-DD") });
    setSelectedDate(moment.range(date.clone().subtract(0, "days"), date.clone()));
    closeCalendarFunc()
  };
  const closeCalendarFunc = () => {
    setTimeout(() => {
         setDatePickerOpen(false);
    }, 200);
  };
  const actionEvents = showCalendar
    ? {}
    : { onClick: () => setDatePickerOpen(true)};
  const dateSelectionactions = singleDateSelection
    ? { onSelectStart: onlySingleDate }
    : { onSelect: onSelect };
  const today = moment();
  return (
    <DatePickerStyle {...actionEvents} {...rest} >
      {children}
      {isDatepickerOpen ? (
        <DatePickerContainer
          realtivePosition={showCalendar}
          alignment={alignment || "left"}
        >
          <DateRangePicker
            name={name}
            value={
              selectedDate ||
              moment.range(today.clone().subtract(1, "days"), today.clone())
            }
            singleDateRange={singleDateSelection}
            {...dateSelectionactions}
          />
          <Footer showFooter={footerVisible}>
            <Button
              size="small"
              boxHeight="26px"
              bgColor="#ed5434"
              width="54px"
              margin="0 4px"
              onClick={() => {
                closeCalendarFunc()
              }}
            >
              OK{" "}
            </Button>
          </Footer>
        </DatePickerContainer>
      ) : null}
    </DatePickerStyle>
  );
};

export default DatePicker;
