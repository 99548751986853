import React, { Fragment, Component } from 'react';
import { withLastLocation } from "react-router-last-location";
import BulkUploadWrapper from './dashboard/organization-roles-users/BulkUploadWrapper';
import HeaderCard from '../components/Dasboard_Components/HeaderCard';
import { DASHBOARDROUTES } from '../helpers/constants/routes';
import Button from './ekyc/rsa/components/Button';
import { ButtonContainer } from './dashboard/dashboardLayoutStyle';

class IFSCVerification extends Component {


  triggerUploadAction=()=>{}

  render() {
    return (
      <Fragment>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#3E96C8",
              color: "#fff",
              padding: "13px",
              border: "1px solid #212323",
              borderRadius: "2px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight:300,
              transform: 'translate(283px,84px)', // Shifts the button 10px down



            }}
            onClick={() => {
              this.props.history.push(DASHBOARDROUTES.IFSC_CHANGE_VERIFICATION);
            }}
          >
            Custom IFSC Update
          </button>
      
        <BulkUploadWrapper
          headerText={"Ifsc Verification"}
          downloadParam={`&sub_project=${"id"}`}
          processAction={this.triggerUploadAction}
          enableSuccessAlert={false}
          uploadSuccessText={"Upload Process Initiated"}
          customElement={null}
          bulkUploadType={"ifsc_codes"}
        />
  </div>
      </Fragment>
    );
  }
}

export default withLastLocation(IFSCVerification);
