export const ImageMobileStyle = {
  width: "100%",
  objectFit: "contain"
}
export const secureSectionContainer = {
  display: "flex",
  justifyContent: "center", 
  alignItems: "center",   
  backgroundColor: "white",
  margin: "5px 0 90px 0",
  height: "60px",
  padding: "8px",
  borderRadius: "6px",
};


export const secureItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: "0 5px",
  padding: "5px",
  height: "100%",
  flex: 1,
};

export const secureLogo_iso = {
  width: "45px",
  height: "45px",
  margin: "0 5px",
  objectFit: "cover",
};
export const secureLogo = {
  width: "55px",
  height: "55px",
  margin: "0 5px",
  objectFit: "cover",
};
export const secureLogo_Shield = {
  width: "35px",
  height: "35px",
  margin: "0 5px",
  objectFit: "cover",
};

export const secureTextContainer = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "5px",
};

export const secureTitle = {
  fontFamily: "barlow",
  fontSize: "15px",
  color: "#555555",
  margin: 0,
  fontStyle: "normal",
  fontWeight: 500,
};


export const secureSubtitle = {
  fontFamily: "barlow",
  fontSize: "10px",
  color: "#555555", 
  margin: 0,
};


export const sslContainer = {
  display: "flex",
  flexDirection: "column", 
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  margin: "5px 0 40px 0",
  height: "120px",
  padding: "8px",
  width: "230px",
  borderRadius: "6px",
  marginLeft: "auto",
  marginRight: "auto",
};

export const sslTitle = {
  display: "flex",
  flexDirection: "row", 
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

export const logoImage = {
  width: "35px",
  height: "35px",
  marginRight: "10px",
  objectFit: "cover",
};

export const textContainer = {
  display: "flex",
  flexDirection: "column", 
  justifyContent: "center",
  alignItems: "center",
};

export const titleSSL = {
  fontSize: "12px",
  fontFamily: "barlow",
  color: "#555555",
  marginLeft:"10px",
 
};

export const titleDataSecurity = {
  fontSize: "10px",
  fontFamily: "barlow",
  color: "#555555",
  margin: "0",
};

export const titleDataSecurity1 = {
  fontSize: "14px",
  fontFamily: "barlow",
  color: "#555555",
  margin: "10px 0 0", 
  textAlign: "center",
};
