import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.div`
width: 44px;
height: 29px;
background: ${(props)=>props.check === "selected" ? "#4770C1": "#FFFFFF"};
border: 1px solid ${(props)=>props.check === "selected" ? "#4770C1": "#909191"};
color:${(props)=>props.check === "selected" ? "#FFFFFF": "#909191"};
border-radius: 2px;
display:flex;
align-items:center;
justify-content:center;
margin-right:12px;
cursor:pointer;
`;

export default function Button({children,onClick,check}) {
  return (
    <StyledButton onClick={onClick} check={check}>{children}</StyledButton>
  )
}
