import React, { Fragment } from 'react';
import styled from 'styled-components';
import { black700, curiousBlue300, royalBlue300 } from '../../theme/colors';
import { boldHeading, miniCaption, regularHeading } from '../../theme/typography';
import Card from './Card';
import { semiBold } from '../../theme/typeface';

const RootCard = styled.div`
    margin: 32px 20px 24px;
`

const MainText = styled.p`
    ${boldHeading.small};
    margin: 0px;
    color: ${black700};
`;

const SubText = styled.p`
    ${miniCaption.semiBold};
    margin: 7px 0px 0px;
    color: ${props => props.subTextColor || curiousBlue300};
`;

const IconCardStyle = styled.div`
    margin: 18px 20px 24px;
`;

const IconStyle = styled.div`
    text-align:right;
    svg{
        width:32px;
        height:32px;
        color: ${royalBlue300};
    }
`;
const TitleStyle = styled.p`
    margin:0;
    width:70%;
    ${regularHeading.mini}
    line-height:125%;
    font-weight: ${semiBold};
`;

const HeaderCard = ({
    mainText = '0',
    subText = 'Total No. of Users',
    onClick = () => false,
    clickable = false,
    subTextColor,
    iconCard,
    cardWidth='285px',
    cardHeight='120px'
}) => {
    return (
        <Card width={cardWidth} height={cardHeight} onClick={onClick} clickable={clickable}>
                {iconCard ?
                <IconCardStyle>
                    <IconStyle>{mainText}</IconStyle>
                    <TitleStyle>{subText}</TitleStyle>
                </IconCardStyle>
                :
                <RootCard>
                    <MainText>{mainText}</MainText>
                    {subText ? <SubText subTextColor = {subTextColor}>{subText}</SubText> : null}
                </RootCard>
                }
        </Card>
    )
}

export default HeaderCard;