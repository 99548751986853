import styled, { css } from 'styled-components';
import { white } from '../../theme/colors';
import themeSizes from "../../theme/themeSizes";

const Card = styled.section`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    margin-right:24px;
    margin-bottom:24px;
    margin:${props => props.margin && props.margin };
    background: ${white};
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
    
    @media ${themeSizes.mobile}{
        width: 100% !important
    }
    
    ${props => props.clickable &&
        css`
            cursor: pointer;

            &:hover{
                box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.26);
                transform: translate(-2px, -2px);
            }
        `
    }

    ${props => props.extraStyles && css`
        ${props.extraStyles}
    `}
`;

export default Card
