import React, { Component } from "react";
import { StyledTable, THEAD, THD, TBODY, TR, TD } from "../../../components/table/table";
import { DashboardTextBox } from "../components/DashboardTexBox";
import _cloneDeep from "lodash/cloneDeep";
import { green300, black200, black700 } from "../../../theme/colors";
import { Button } from "../../../components/Button";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import {getDayOrNumberSuffix} from "../../../helpers/getDayOrNumbersuffix";
import { SelectFilter } from "../components/SelectFilter";
import { transform } from "lodash";
export default class SkuTableOutlet extends Component {
  state = {
    allQuestionsAndAnswers: [],
    selectedAnswers: {},
  }
  

  handleSingleEdit = (question_id, value) => {
    const { allQuestionsAndAnswers } = this.state;
    const updatedAnswers = { ...allQuestionsAndAnswers };
    if (updatedAnswers[question_id]) {
      updatedAnswers[question_id].single_input = value;
      updatedAnswers[question_id].is_edited = true;
    }
    this.setState({
      allQuestionsAndAnswers: updatedAnswers
    })
  }


  componentDidMount() {
    const { outlet_group_details } = this.props;
    const allQuestionsAndAnswers = {};
    outlet_group_details.forEach(question => {
      question.related_answers.forEach(answers => {
        allQuestionsAndAnswers[answers.id] = answers
        delete  allQuestionsAndAnswers[answers.id].multiple_images_input
        delete  allQuestionsAndAnswers[answers.id].single_image_input
      })
    })
    this.setState({
      allQuestionsAndAnswers
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { outlet_group_details = [] } = this.props;
    if(prevProps.outlet_group_details !== outlet_group_details){
      const allQuestionsAndAnswers = {}
      outlet_group_details.forEach(question => {
        question.related_answers.forEach(answers => {
          allQuestionsAndAnswers[answers.id] = answers
          delete  allQuestionsAndAnswers[answers.id].multiple_images_input
          delete  allQuestionsAndAnswers[answers.id].single_image_input
        })
      })
      this.setState({
        allQuestionsAndAnswers
      })
    }
  }

  handleSave = () => {
    const { allQuestionsAndAnswers } = this.state;
    const { on_save } = this.props;
    const updatedAnswers = { ...allQuestionsAndAnswers };
    const skuRequestBody = {
      object_type: "answers",
      data_to_create: null,
      data_to_update: updatedAnswers
    }
    on_save(skuRequestBody);
      this.setState({ allQuestionsAndAnswers: updatedAnswers });
  };

  handleMultipleEdit = (question_id, selectedOptions) => {
    const { allQuestionsAndAnswers, selectedAnswers } = this.state;
    const updatedSelectedAnswers = {
      ...selectedAnswers,
      [question_id]: selectedOptions.map(option => option.label) 
    }
    const updatedAnswers = { ...allQuestionsAndAnswers };
    if (updatedAnswers[question_id]) {
      updatedAnswers[question_id].multiple_answers_input = selectedOptions.map(option => option.value);
      updatedAnswers[question_id].is_edited = true;
    }
  
    this.setState({
      allQuestionsAndAnswers: updatedAnswers,
      selectedAnswers: updatedSelectedAnswers, 
    });
  };
  
  render() {
    const { sku_products = [], outlet_group_details = [], disabled, requestInProcess } = this.props;
    const { allQuestionsAndAnswers, selectedAnswers } = this.state;

    return (
      <>
        <StyledTable>
          <THEAD>
                    <THD style={{position:'sticky',left:'-30px',zIndex:1,background:'white'}}> Question</THD>
            {
              sku_products.map(product =>
                                <THD>{product.name.toLowerCase().split(' ').map(x=>x[0]?.toUpperCase()+x.slice(1)).join(' ')}</THD>
              )
            }
          </THEAD>
          <TBODY>
            {outlet_group_details.map((question, upperIndex) => {
              const { related_answers = [] } = question;
              return (
                <>
                <TR style={{height: "auto"}}>
                  <div style={{position:'sticky',left:'-30px',zIndex:1,background:'white'}}>
                    <TD style={{height: "auto"}}>{question.name}</TD>
                  </div>
                  {
                    sku_products.map((product) => {
                    let isDone = false;
                    
                    return (
                      <>
                          {
                            related_answers.map((answer, index) => {

                              const { sku_product = '', answer_type = 'text' } = answer;
                          if (answer.sku_product === product.id) {
                            isDone = true;
                            let previous_answers = related_answers.previous_answers || {}
                            const actual_answer = answer.sku_product === product.id ? allQuestionsAndAnswers[answer.id]?.single_input : null;

                            const multipleDetails = [];

                            if (answer.answer_type === "multiple_choice") {
                              const validationCondition = Array.isArray(answer.sku_product_validations)
                                        ? answer.sku_product_validations.join(';') 
                                        : answer.sku_product_validations || '';     

                              validationCondition.split(";").forEach(item => {
                                multipleDetails.push({ label: item, value: item });
                              });
                            }

                        
                            const mappedValues = selectedAnswers[answer.id] || answer.multiple_answers_input.map(id => {
                              const option = multipleDetails.find(option => option.value === id);
                              return option ? option.label : id;
                            });
                            
                            return (
                              <TD key={index} data-tip data-for={`${answer.sku_product}-tip-${index}-${upperIndex}`} style={{ overflow: "visible", border: "None", height: "auto" }}>
                                {answer_type === "multiple_choice" ? (
                                      <SelectFilter
                                        props={{
                                          value: mappedValues.map(value => ({ value, label: value })),
                                          options: multipleDetails,
                                          onChange: (selectedOptions) => this.handleMultipleEdit(answer.id, selectedOptions),
                                          border: `1px solid ${black200}`,
                                          placeholderColor: black700,
                                          isMulti: true,
                                          width: "165px",
                                          isDisabled:disabled
                                        }}
                                        noOptionMessage="No data available"
                                        customStyles={{
                                          borderRadius: "2px",
                                        }}
                                      />
                                    ) : answer.multiple_answers_input && answer.multiple_answers_input.length > 0 ? (
                                      <SelectFilter
                                        props={{
                                          value: mappedValues.map(value => ({ value, label: value })),
                                          options: multipleDetails,
                                          onChange: (selectedOptions) => this.handleMultipleEdit(answer.id, selectedOptions),
                                          border: `1px solid ${black200}`,
                                          placeholderColor: black700,
                                          isMulti: true,
                                          width: "165px",
                                          isDisabled:disabled
                                        }}
                                        noOptionMessage="No data available"
                                        customStyles={{
                                          borderRadius: "2px",
                                        }}
                                      />
                                    ) : (
                                      <DashboardTextBox
                                        isSKU
                                        isSearchBox={true}
                                        boxWidth={"175px"}
                                        boxHeight="40px"
                                        type="text"

                                        placeholder={`Enter ${answer_type}`}
                                        // value={answer.sku_product === product.id ? answer.single_input : null}
                                        value={actual_answer}
                                        disabled={disabled}
                                        onChange={
                                          (e) => {
                                                    answer.sku_product === product.id ? this.handleSingleEdit(answer.id, e.target.value) : console.log("TBD")
                                            }
                                          }
                                      />
                                    )}

                                    {(actual_answer && actual_answer.length > 10) && (
                                                                              <ReactTooltip id={`${answer.sku_product}-tip-${index}-${upperIndex}`} >
                                    {actual_answer}
                                  </ReactTooltip>
                                )}


                                    {Object.keys(previous_answers).length > 0 &&
                                        (<>
                                    <InfoIcon data-tip data-for={`tooltip-${answer.sku_product}`} />
                                                <ReactTooltip
                                                    id={`tooltip-${answer.sku_product}`}
                                                    place={"bottom"}
                                                    type={"dark"}
                                                                                  >
                                                {Object.keys(previous_answers).map((key) => (
                                                    <>
                                                        <div>
                                          {getDayOrNumberSuffix(key)} Prev: &nbsp;&nbsp;
                                          {previous_answers[key].single_input}
                                          <p>{previous_answers[key].sub_project_name}</p>
                                        </div>
                                                    </>
                                      ))}
                                    </ReactTooltip>
                                        </>)
                                    }

                              </TD>
                            )
                          }
                          if (related_answers.length - 1 === index && !isDone) {
                                return (
                                    <TD>N/A</TD>
                                )
                          }
                            })
                          }
                      </>
                        )
                    })
                  }
                </TR>
                </>
              )
            })}
          </TBODY>

          {!disabled && (
            <Button
              size="medium"
              type="submit"
              bgColor={green300}
              isLoading={requestInProcess}
              onClick={this.handleSave}
              margin={"15px"}
            >
              Save and Next
            </Button>
          )}
                    
        </StyledTable>
                
                

      </>
        )
  }
}
