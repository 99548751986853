import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";

const CalendarComponent = ({ 
  name, 
  getSelectedDates, 
  showCalendar = false 
}) => {
  const [showFromDatePicker, setShowFromDatePicker] = useState(false);
  const [showToDatePicker, setShowToDatePicker] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const toggleFromDatePicker = () => {
    setShowFromDatePicker((prev) => !prev);
    if (!showFromDatePicker) setShowToDatePicker(false);
  };

  const toggleToDatePicker = () => {
    setShowToDatePicker((prev) => !prev);
  };

  const handleFromDateChange = (date) => {
    const minDate = moment(date).subtract(30, "days").toDate(); // 30 days before the selected date
    const maxDate = moment(date).add(30, "days").toDate(); // 30 days after the selected date

    setFromDate(date);
    setToDate(null); // Reset toDate to avoid conflicts
    setShowFromDatePicker(false);
    
    // Update the minDate for "toDate" based on the new "fromDate"
    setToDate(minDate <= toDate && toDate <= maxDate ? toDate : null);
  };

  const handleToDateChange = (date) => {
    if (!fromDate) return; // Don't allow selecting toDate without fromDate

    const minDate = moment(fromDate).subtract(30, "days").toDate(); // 30 days before the selected "fromDate"
    const maxDate = moment(fromDate).add(30, "days").toDate(); // 30 days after the selected "fromDate"

    if (date >= minDate && date <= maxDate) {
      setToDate(date);
    } else {
      alert(`To Date must be within 30 days of From Date`);
    }
    setShowToDatePicker(false);
  };

  const handleOnSubmitDate = () => {
    if (fromDate && toDate) {
      const dateRange = {
        from: moment(fromDate).format("YYYY-MM-DD"),
        to: moment(toDate).format("YYYY-MM-DD"),
      };
      getSelectedDates && getSelectedDates({ name, dateRange });
    }
  };

  return (
    <CalendarContainer>
      <Label>From</Label>
      <DateSection>
        <InputWrapper>
          <span>
            {fromDate
              ? moment(fromDate).format("YYYY-MM-DD")
              : "Select a date"}
          </span>
          <i
            className="fa fa-calendar"
            onClick={toggleFromDatePicker}
            aria-hidden="true"
          ></i>
        </InputWrapper>
        {showFromDatePicker && (
          <StyledDatePicker>
            <DatePicker
              selected={fromDate}
              onChange={handleFromDateChange}
              dateFormat="yyyy/MM/dd"
              inline
            />
          </StyledDatePicker>
        )}
      </DateSection>

      <Label>To</Label>
      <DateSection>
        <InputWrapper>
          <span>
            {toDate
              ? moment(toDate).format("YYYY-MM-DD")
              : "Select a date"}
          </span>
          <i
            className="fa fa-calendar"
            onClick={toggleToDatePicker}
            aria-hidden="true"
          ></i>
        </InputWrapper>
        {showToDatePicker && (
          <StyledDatePicker>
            <DatePicker
              selected={toDate}
              onChange={handleToDateChange}
              dateFormat="yyyy/MM/dd"
              minDate={fromDate} // Ensure "toDate" cannot be before "fromDate"
              maxDate={fromDate ? moment(fromDate).add(30, "days").toDate() : null} // Limit to 30 days
              inline
            />
          </StyledDatePicker>
        )}
      </DateSection>

      <SubmitButton onClick={handleOnSubmitDate}>Submit</SubmitButton>
    </CalendarContainer>
  );
};

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  width: 100%;
`;

const Label = styled.h4`
  margin: 0;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
`;

const DateSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 10px;
  position: relative;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 13px;
    color: #666;
  }
  i {
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #007bff;
    }
  }
`;

const StyledDatePicker = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: ${({ isOpen }) => (isOpen ? '10px' : '0')};  // Conditional margin-top
`;

const SubmitButton = styled.button`
  background-color: #000000;  
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #333333;  
  }
`;

export default CalendarComponent;
