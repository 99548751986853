import React from "react";
import styled, { css } from "styled-components";
import { microHeading, defaultFont } from "../theme/typography";
import { semiBold } from "../theme/typeface";
import { black300, black700, green300, white } from "../theme/colors";
import themeSizes from "../theme/themeSizes";

const LargeButton = styled.button`
  ${microHeading.bold}
  min-width: ${(props) => (props.boxWidth ? props.boxWidth : "125px")};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  height: ${(props) => (props.boxHeight ? props.boxHeight : "46px")};
  background: ${(props) => props.bgColor || "#2CAE66"};
  border-radius: ${(props) => (props.boxRadius ? props.boxRadius : "4px")};
  margin: ${(props) => props.margin || "0 12px 0 0"};
  font-weight: ${semiBold};
  color: ${(props) => props.color || "#FFFFFF"};
  outline: none;
  border: ${(props) => (props.border ? props.border : "none")};;
  cursor: pointer;
  padding: ${(props) => props.padding || "0 40px"};
  position: relative;
  ${(props) =>
    props.changeOnlyBgColor &&
    css`
      background: ${(props) => props.bgColor};
      border: 1px solid ${(props) => props.color || black700};
      color: ${(props) => props.color || black700};
    `}
  ${(props) =>
    props.isLoading &&
    css`
      padding: 0 35px 0 20px;
      cursor: not-allowed;
      transition: all 0.2s;
      &:after {
        content: " ";
        width: 16px;
        height: 16px;
        opacity: 0.6;
        right: 18px;
        top: 16px;
        border: 3px solid;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        background-clip: padding-box;
        border-left-color: transparent;
        animation: spin 1s linear infinite;
      }
    `};
  :disabled,
  &[disabled] {
    cursor: not-allowed;
    background: ${({ disabledBgColor }) => disabledBgColor || black300};
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  };
  ${(props)=> props.check === "saveAndExit" ? `@media ${themeSizes.mobile} {
    width:100%
  }` : ""}

`;

const MediumButton = styled.button`
  outline: none;
  position: relative;
  min-width: 92px;
  width: ${(props) => props.boxWidth};
  height: ${(props) => (props.boxHeight ? props.boxHeight : "39px")};
  padding: 0 16px;
  margin: ${(props) => props.margin || "0 12px 0 0"};
  ${defaultFont}
  font-weight : ${semiBold};
  color: ${(props) => props.color || black700};
  border-radius: ${(props) => (props.boxRadius ? props.boxRadius : "2px")};
  border: 1px solid ${(props) => props.color || black700};
  border: ${(props) => props.check === "ekyc" && props.border};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
    `};
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `};
  ${(props) =>
    props.bgColor &&
    css`
      background: ${(props) => props.bgColor || green300};
      border: 1px solid ${(props) => props.bgColor || green300};
      border: ${(props) => props.check === "ekyc" && props.border};
      color: ${props.color || white};
    `};
  ${(props) =>
    props.changeOnlyBgColor &&
    css`
      background: ${(props) => props.bgColor};
      border: 1px solid ${(props) => props.color || black700};
      color: ${(props) => props.color || black700};
    `}
  span {
    position: relative;
  }
  svg {
    vertical-align: top;
    position: relative;
    margin-right: 8px;
  }
  :disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  ${(props) =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      transition: all 0.2s;
      span {
        left: -9px;
      }
      &:after {
        content: " ";
        width: 16px;
        height: 16px;
        opacity: 0.6;
        right: 8px;
        top: 11px;
        border: 3px solid;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        background-clip: padding-box;
        border-left-color: transparent;
        animation: spin 1s linear infinite;
      }
    `};
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SmallButton = styled(MediumButton)`
  width: ${({ boxWidth }) => boxWidth || "200px"};
  height: ${(props) => (props.boxHeight ? props.boxHeight : "39px")};
`;
export const Button = ({
  children,
  onClick = () => {},
  type = "button",
  isLoading = false,
  width = "max-content",
  borderRadius = "",
  fontStyle,
  isDisabled,
  size,
  bgColor,
  color,
  margin,
  noBorder,
  border = "",
  cursor,
  boxHeight,
  changeOnlyBgColor,
  disabledBgColor,
  padding = "",
  check,
  htmlFor,
  borderColor,
}) => {
  let ButtonWrapper = LargeButton;
  switch (size) {
    case "medium":
      ButtonWrapper = MediumButton;
      break;
    case "small":
      ButtonWrapper = SmallButton;
      break;
  }


  return (
    <ButtonWrapper
      onClick={(e) => {
        e && e.preventDefault();
        if (!isLoading) {
          onClick && onClick(e);
        }
      }}
      type={type}
      check={check}
      isLoading={isLoading}
      boxWidth={width}
      boxRadius={borderRadius}
      style={fontStyle}
      disabled={isDisabled}
      bgColor={bgColor}
      color={color}
      margin={margin}
      noBorder={noBorder}
      border={border}
      cursor={cursor}
      boxHeight={boxHeight}
      changeOnlyBgColor={changeOnlyBgColor}
      disabledBgColor={disabledBgColor}
      padding={padding}
      htmlFor={htmlFor}
      borderColor={borderColor}
    >
      <span>{children}</span>
    </ButtonWrapper>
  );
};
